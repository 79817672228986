import React, { useEffect, useState } from 'react';
import './GamesDisplay.css'; // Ensure you have corresponding CSS for styling
import GameGridDisplay from './GameGridDisplay'
import scoreboardJson from './scoreboard.json'
import PostStartGrid from './PostStartGrid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function GamesDisplay({ contest, games }) {
    const [activeGame, setActiveGame] = React.useState(games[1]);
    const [scoreboard, setScoreboard] = useState({});
    const [gameScores, setGameScores] = useState({});

    useEffect(() => {
        // Call the function to fetch data from ESPN's API
        fetchESPNData()
            .then(data => {
                // Assuming you have a function to extract scores by competition ID
                const scores = getScoresByCompetitionId(data.events, activeGame.competitionId);
                setGameScores(scores);
            })
            .catch(error => {
                console.error('Failed to fetch data:', error);
            });
    }, [activeGame]);

    // useEffect(() => {
    //     try {
    //         fetchESPNData()
    //             .then(data => {
    //                 let count = 0
    //                 for (let event of data.events) {
    //                     if (event.status?.type?.name == "STATUS_IN_PROGRESS") {
    //                         setActiveGame(games[count])
    //                         return
    //                     }
    //                     count++
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error('Failed to fetch data:', error);
    //             });
    //     } catch (error) {

    //     }
    // }, [])


    const fetchESPNData = async () => {
        try {
            const res = await fetch('https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard?limit=1000&dates=20241128-20241128');
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            return await res.json();
        } catch (error) {
            console.error('Error fetching ESPN data:', error);
        }
    }

    const getScoresByCompetitionId = (events, competitionId) => {
        for (let event of events) {
            if (event.competitions) {
                for (let competition of event.competitions) {
                    if (competition.id == competitionId) {
                        let scores = { home: {}, away: {} };
                        competition.competitors.forEach(competitor => {
                            const teamType = competitor.homeAway === 'home' ? 'home' : 'away';
                            scores[teamType] = {
                                team: competitor.team.displayName,
                                score: competitor.score
                            };
                        });
                        return scores;
                    }
                }
            }
        }
        return {};
    };


    return (
        <div className="games-display-container">

            <InstructionCard />
            <Box sx={{ marginX: '10vw', marginY: 2 }}>
                <Card>
                    <CardContent>
                        <Box className="legend-container" sx={{ marginTop: 0 }}>
                            <Typography variant="subtitle2" component="p">
                                Legend:
                            </Typography>
                            <Box display="flex" alignItems="center" sx={{ marginTop: 2 }}>
                                <div className="legend-square super-highlighted"></div>
                                <Typography variant="body2" component="p">
                                    - Current score winner
                                </Typography>
                            </Box>
                            <Box sx={{ marginTop: 1 }} display="flex" alignItems="center">
                                <div className="legend-square highlighted"></div>
                                <Typography variant="body2" component="p">
                                    - Potential winner
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            {games?.length > 1 ?
                <div className="game-tabs">
                    {games.map((game, index) => (
                        <button
                            key={index}
                            onClick={() => setActiveGame(game)}
                            className={activeGame === game ? 'active' : ''}
                        >
                            Game {index + 1}: {game.awayTeam} vs {game.homeTeam}
                        </button>
                    ))}
                </div>
                : null
            }

            <PostStartGrid game={activeGame} participants={contest.participants} gameScores={gameScores} />
        </div>
    );
}




const InstructionCard = ({ squaresRemaining }) => (
    <Box sx={{ marginX: '10vw', marginY: 2 }}>
        <Card>
            <CardContent>
                <Typography variant="h6">
                    Prize Distribution:
                </Typography>
                <Typography paragraph>
                    - End of 1st Quarter: $100<br />
                    - End of 2nd Quarter: $100<br />
                    - End of 3rd Quarter: $100<br />
                    - End of Game: $190<br />
                </Typography>

            </CardContent>
        </Card>
    </Box>
);